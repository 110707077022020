import React, { useState, useLayoutEffect } from "react"
import axios from "axios"
import { useIdentityContext } from 'react-netlify-identity-gotrue'
import { navigate } from "gatsby"
import { useForm } from "react-hook-form"
import {
    StyledForm,
    StyledFieldset,
    StyledInput,
    StyledLabel,
    StyledLabelHeader,
    StyledFormElementWrapper
  } from "../styledComponents/contact"
import { StyledButton, StyledButtonViewPassword } from "../styledComponents/button"
import Icon from "./icon"

const SignupIdentityFormFhtj = ({roles, customer, products}) => {
  const identity = useIdentityContext()
  const { getValues, register, handleSubmit, setValue ,formState: { errors } } = useForm()
  const [formError, setFormError] = useState(false)
  const [signingUp, setSigningUp] = useState(false)
  const [passwordShown, setPasswordShown] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [freeMindfullCollection , setFreeMindfullCollection] = useState({})

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  }

  const onSubmit = async (data) => {
    setSigningUp(true)
    setFormError(false)
    let giftProduct
    data["app_metadata"] = {...data["app_metadata"] , roles: ["healing-tuning-blueprint"]}
    axios.post("/.netlify/functions/formHandler" , {emailAddress:data.email, fhtjRegistered:'true' })
    identity
      .signup(data)

      .then(() => {
        setSigningUp(false)
        navigate('/my-account')
      })
      .catch(e => {
        setFormError(e.message)
        setSigningUp(false)
      })
  }

  useLayoutEffect(() => {
    if(typeof window != undefined && window.document && customer && customer.name){
      setValue("user_metadata.full_name" , customer.name)
      setValue("app_metadata.roles" , ['healing-tuning-blueprint'])
      setValue("email" , customer.email)
    }
    if (typeof window !== "undefined" || window.document) {
      const urlParams = new URLSearchParams(window.location.search)
      const id = urlParams.get('id')
      setFreeMindfullCollection(id)
      return
    }
  }, [customer])

    return (
        <>
            <StyledForm onSubmit={handleSubmit(onSubmit)} marginTop >
              <StyledFieldset>
                <StyledFormElementWrapper>
                    <StyledLabel htmlFor="user_metadata.full_name">
                      <StyledLabelHeader
                        required
                        isEmpty={errors.user_metadata?.full_name}
                      >
                        Full name
                      </StyledLabelHeader>
                      <StyledInput
                        id="full_name"
                        name="user_metadata.full_name"
                        type="text"
                        required
                        {...register( 'user_metadata.full_name', { required: true })}
                      className={`${signingUp && 'disabled'}`}
                      />
                    </StyledLabel>
                    {errors.user_metadata?.full_name ? (
                      <span className="error-message">Name is required</span>
                    ) : null}
                  </StyledFormElementWrapper>

                  <StyledFormElementWrapper>
                    <StyledLabel htmlFor="email">
                      <StyledLabelHeader
                        required
                        isEmpty={errors.email}
                      >
                        Email
                      </StyledLabelHeader>
                      <StyledInput
                        id="email"
                        name="email"
                        type="text"
                        required
                        {...register('email', { required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}
                      className={`${signingUp && 'disabled'}`}
                      />
                    </StyledLabel>
                    {errors.email ? (
                      <span className="error-message">Email is required, use correct format</span>
                    ) : null}
                  </StyledFormElementWrapper>

                 
                  <StyledFormElementWrapper>
                    <StyledLabel htmlFor="password">
                      <StyledLabelHeader
                        required
                        isEmpty={errors.password}
                      >
                        Password
                      </StyledLabelHeader>
                      <StyledInput
                        {...register('password',{ required: true })}
                        className={`${signingUp && 'disabled'}`}
                        name="password"
                        type={passwordShown ? "text" : "password"}
                      />
                      <StyledButtonViewPassword onClick={togglePassword} tabIndex="-1"><Icon name="eye"/></StyledButtonViewPassword>
                    </StyledLabel>
                    {errors.password ? (
                      <span className="error-message">Password is required</span>
                    ) : null}
                  </StyledFormElementWrapper>
                  {products &&
                    <StyledInput
                      name="product"
                      value={products}
                      type="hidden"
                      {...register('product',{ required: false })}
                    />
                  }

                  <StyledButton
                    className={` ${signingUp && ''}`}
                    type="submit">
                    {'Submit'}
                  </StyledButton>
                  {/* <div>
                    {formError && <p >That didn't seem to work right!</p>}
                  </div> */}
                </StyledFieldset>
            </StyledForm>
              </>
  )
}

export default SignupIdentityFormFhtj